import Home from "../../components/Home";
import Query from "../../components/Query";
import HOME_PAGE_QUERY from "../../queries/home_page";

const HomeContainer = () => {
  return (
    <Query query={HOME_PAGE_QUERY}>
        {({ data: { pageHome } }) => {
        //console.log(pageHome)
            return <Home page={pageHome} />;
        }}
    </Query>
  );
};

export default HomeContainer;
