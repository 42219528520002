import React, { Fragment, useEffect, useState, useRef } from "react";

import { useSwipeable } from "react-swipeable";
import { useHistory } from 'react-router-dom';

import { useResizeDetector  } from 'react-resize-detector';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function  SoundDesign({page, pagelength}){

    const [iframeFullHeight,setIframeFullHeight] = useState(0);
    const [iframeVideoWidth,setIframeVideoWidth] = useState(0);
    const [iframeHeaderHeight,setIframeHeaderHeight] = useState(0);

    const [firstTime,setFirstTime] = useState(false);
    const [iframeStatus,setIframeStatus] = useState(0);
    

    const divRef= useRef(null);

    const { width, height, ref } = useResizeDetector({
        handleHeight: false,
        refreshMode: 'debounce',
        refreshRate: 250
      });

    //   console.log(pagelength);
    //   console.log(page);

    //   console.log(page.MediaContent);
    //   console.log(page.MediaContent.length);
      



    useEffect(() => {
    if(divRef.current){
        //console.log(firstTime);
        if(firstTime===false){
                // console.log(divRef);
                // console.log(iframeStatus);
                //   console.log(divRef.current.clientWidth)
                setIframeFullHeight(divRef.current.clientWidth/1.32);
                setIframeVideoWidth(divRef.current.clientWidth/1.78);
                setIframeHeaderHeight((divRef.current.clientWidth/1.78)/3);
                setFirstTime(true);
        }
        if (width || height) {
            // console.log(divRef);
            // console.log(iframeStatus);
            //   console.log(divRef.current.clientWidth)
            setIframeFullHeight(divRef.current.clientWidth/1.32);
            setIframeVideoWidth(divRef.current.clientWidth/1.78);
            setIframeHeaderHeight((divRef.current.clientWidth/1.78)/3);
        }

    }
    });

    function youTubeGetID(url){
        url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        let id_link = (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
        return "https://www.youtube.com/embed/"+id_link+"?autoplay=0&amp;mute=0&amp;controls=1&amp;loop=0&amp;origin=https%3A%2F%2Fwww.dimolosound.com&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=19"
    }

    function vimeoGetID(url){
        var m = url.match(/^.+vimeo.com\/(.*\/)?([^#\?]*)/);
        let id_link = m ? m[2] || m[1] : null
        return "https://player.vimeo.com/video/"+id_link+"?title=0&amp;app_id=122963";
    }
    // code for swipe START
    let history = useHistory();
    function redirectTo(params) {
        if(window.innerWidth < 768){
            history.push(params);
        }
        // console.log(params);
    }
    const handlers = useSwipeable({
      onSwipedLeft: () =>redirectTo('/music'),
      onSwipedRight: () =>  redirectTo('/voice-over'),
      preventDefaultTouchmoveEvent: true,
      trackMouse: true,
      delta: 150
    });
    // code for swipe END

        return <Fragment>
        <div className="wrapper wrapper__tiny "   {...handlers}  ref={ref}>
            <div className="sound-design-inner">
                <div className="sound-design ">
                    <Container>
                        <Row>
                            {
                                page.MediaContent.map((item, index) => {
                                    if(item.contentType==="youtube"){
                                        return (
                                            <Col lg="4" md="6"  className="col-hd-4 sound-design__inner" key={index}>

                                                <div className="sound-design__header" style={{height: iframeHeaderHeight}}> 
                                                    {item.title_row_one != null ? <p className="sound-design__title-one">{item.title_row_one}</p> : ''}
                                                    {item.title_row_two != null ? <p className="sound-design__title-two">{item.title_row_two}</p> : ''}
                                                </div>
                                                <div ref={divRef} className={"sound-design_item "+(iframeStatus === pagelength ? "hide" : 'show')}> 
                                                    <iframe height={iframeVideoWidth+'px'} frameBorder="0" allowFullScreen="1" onLoad={()=>{setIframeStatus(iframeStatus+1) }}  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" width="100%"  src={youTubeGetID(item.url)} ></iframe>
                                                </div>
                                            </Col>
                                        );
                                    }else if(item.contentType==="vimeo"){
                                        return (
                                            <Col lg="4" md="6"  className="col-hd-4 sound-design__inner" key={index}>
                                                {item.title_row_one != null  ?
                                                    <div className="sound-design__header" style={{height: iframeHeaderHeight}}> 
                                                        {item.title_row_one != null ? <p className="sound-design__title-one">{item.title_row_one}</p> : ''}
                                                        {item.title_row_two != null ? <p className="sound-design__title-two">{item.title_row_two}</p> : ''}
                                                    </div>
                                                : ''}
                                                <div ref={divRef} className={"sound-design_item "+(iframeStatus === pagelength ? "hide" : 'show')}> 
                                                <iframe height={item.title_row_one != null ? iframeVideoWidth+'px': iframeFullHeight+'px'} onLoad={()=>{setIframeStatus(iframeStatus+1) }} src={vimeoGetID(item.url)} frameborder="0" allow="autoplay; fullscreen;" allowFullScreen="" title="Data Driven Marketing" data-ready="true"></iframe>
                                                </div>
                                            </Col>
                                        );
                                    }
                                })
                            }
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
</Fragment>;
}

export default SoundDesign;