import React, {Fragment} from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useSwipeable } from "react-swipeable";
import { useEffect } from 'react';

import {useHistory} from 'react-router-dom'

// import AboutMe1920 from '../../images/aboutMe/1920.png';
// import AboutMe991 from '../../images/aboutMe/991.png';
// import AboutMe360 from '../../images/aboutMe/360.png';

// import Client_1 from '../../images/company/abou/untime.png';
// import Client_2 from '../../images/company/abou/1+1.png';
// import Client_3 from '../../images/company/abou/loopy.png';
// import Client_4 from '../../images/company/abou/general.png';
// import Client_5 from '../../images/company/abou/games.png';

function  AboutMe ({page}){

    // console.log(page);

    // code for redirection START
    let history = useHistory();
    function redirectTo(params) {
        if(window.innerWidth < 768){
            history.push(params);
        }
        // console.log(params);
    }
    const handlers = useSwipeable({
      onSwipedLeft: () =>redirectTo('/contact'),
      onSwipedRight: () =>  redirectTo('/music'),
      preventDefaultTouchmoveEvent: true,
      trackMouse: true,
      delta: 150
    });
    // code for redirection END

    
  
    useEffect(()=>{
    //   if(true){
    //     console.log(location.pathname);
    //   }
    // console.log(window.innerWidth);
    })

        return <Fragment >
                <div className="wrapper" {...handlers}>
                    <Container>
                        <Row>
                            <Col xs="12" md="12" lg="7" className='col-hd-7 about-me-img-inner'>
                                <img 
                                    className="about-me_img" 
                                    src={page.about_me_background.formats.medium.url} 
                                    alt="" 
                                    srcSet={`${page.about_me_background.formats.thumbnail.url}  300w, ${page.about_me_background.formats.small.url}  768w, ${page.about_me_background.formats.medium.url}  1280w`}
                                    
                                
                                />
                                 <div className="about-me_background" style={{ backgroundImage: `url(${page.about_me_background.formats.medium.url})` }}> </div>
                            </Col>
                            <Col xs="12" md="12" lg="5" className='col-hd-5'>
                                <div className="about-me_text-items" dangerouslySetInnerHTML={{__html: page.about_me_description}}>
                                    
                                </div>
                                <div className="clients">
                                    {
                                        page.Clients.map((item,index)=>{
                                            return(
                                                <div className="clients__item" key={index}>
                                                    <a  rel="noreferrer" target="_blank" href={item.link}>
                                                        <img src={item.image.formats.thumbnail.url}  alt={item.image.formats.thumbnail.name} className="clients_img"/>
                                                    </a>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Fragment>
}

export default AboutMe;