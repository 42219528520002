import gql from "graphql-tag";

const CONTACT_PAGE_QUERY = gql`
    query pageContact{
        pageContact{
            animation_image,
            skype,
            mail,
            mobile,
            telegram,
            instagram,
            whatsapp
        }
    }
`;

export default CONTACT_PAGE_QUERY;
