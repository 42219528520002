import gql from "graphql-tag";

const MUSIC_PAGE_QUERY = gql`
    query pageMusic{
        pageMusic{
            MediaContent{
                title_row_one
                title_row_two
                url
                soundcloud_iframe
                contentType
            }
        }
    }
`;

export default MUSIC_PAGE_QUERY;



