import React, {Fragment} from "react";
import { useSwipeable } from "react-swipeable";
import {useHistory} from 'react-router-dom';


// import convertAnimation from "../../animations/convert.json";
import Lottie from "lottie-react";

const Contact = ({ page }) => {

    // console.log(page)
    // code for swipe START
    let history = useHistory();
    function redirectTo(params) {
        if(window.innerWidth < 768){
            history.push(params);
        }
        // console.log(params);
    }
    const handlers = useSwipeable({
      onSwipedLeft: () =>redirectTo('/implementation'),
      onSwipedRight: () =>  redirectTo('/about-me'),
      preventDefaultTouchmoveEvent: true,
      trackMouse: true,
      delta: 150
    });
    // code for swipe END

        return <Fragment>
                    <div className="wrapper wrapper_no-margin" {...handlers}>
                        <div className="mail-inner">
                            <div className="mail">
                                {page.animation_image != null && page.animation_image != ""
                                        ? 
                                            <Lottie  animationData={page.animation_image} loop={false} className="mail_img"  />
                                        :
                                            ''
                                }
                                <div className="mail_texts">
                                    {page.skype != null && page.skype  != ""
                                        ? 
                                            <div className="mail_text-item">
                                                <span className="mail-text_label">skype: </span>       
                                                <a href="skype:dimolo.molo?call" className="mail-text_text"> {page.skype}</a>    
                                            </div>
                                        :
                                            ''
                                    }
                                    {page.mail != null && page.mail != ""
                                        ? 
                                            <div className="mail_text-item">
                                                <span className="mail-text_label">mail: </span>       
                                                <a href="mailto:dimolosound@gmail.com" className="mail-text_text">{page.mail}</a>    
                                            </div>
                                        :
                                            ''
                                    }
                                    {page.mobile != null && page.mobile != ""
                                        ? 
                                            <div className="mail_text-item">
                                                <span className="mail-text_label">tel: </span>       
                                                <a href="tel:+38 (067) 220 37 66" className="mail-text_text">{page.mobile}</a>    
                                            </div>
                                        :
                                            ''
                                    }
                                    {page.instagram != null && page.instagram != ""
                                        ? 
                                            <div className="mail_text-item">
                                                <span className="mail-text_label">instagram: </span>       
                                                <a href={'https://www.instagram.com/'+page.instagram} className="mail-text_text">{page.instagram}</a>    
                                            </div>
                                        :
                                            ''
                                    }
                                    {page.telegram != null && page.telegram != ""
                                        ? 
                                            <div className="mail_text-item">
                                                <span className="mail-text_label">telegram: </span>       
                                                <a href={'https://t.me/'+page.telegram} className="mail-text_text">{page.telegram}</a>    
                                            </div>
                                        :
                                            ''
                                    }
                                    {page.whatsapp != null && page.whatsapp != ""
                                        ? 
                                            <div className="mail_text-item">
                                                <span className="mail-text_label">whatsapp: </span>       
                                                <a href={'https://api.whatsapp.com/send?phone='+ page.whatsapp} className="mail-text_text">{page.whatsapp}</a>    
                                            </div>
                                        :
                                            ''
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
            </Fragment>;
}

export default Contact;

