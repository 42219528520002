import gql from "graphql-tag";

const VOICEOVER_PAGE_QUERY = gql`
    query pageVoiceOver{
        pageVoiceOver {
            animation_image,
            Implementation_text
        }
    }
`;

export default VOICEOVER_PAGE_QUERY;
