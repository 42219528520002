import React, { Fragment } from "react";

import { useSwipeable } from "react-swipeable";
import { useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// import MicImg from '../../images/implementation_background_1366.svg';

import Lottie from "lottie-react";
// import voiceOverAnimation from "../animations/voice-over.json";

function VoiceOver ({page}){

    // code for swipe START
    let history = useHistory();
    function redirectTo(params) {
        if(window.innerWidth < 768){
            history.push(params);
        }
        // console.log(params);
    }
    const handlers = useSwipeable({
      onSwipedLeft: () =>redirectTo('/sounddesign'),
      onSwipedRight: () =>  redirectTo('/implementation'),
      preventDefaultTouchmoveEvent: true,
      trackMouse: true,
      delta: 150
    });
    // code for swipe END
        
        return <Fragment>
                    <div className="wrapper wrapper_no-margin" {...handlers}>
                        <div className="implementation-inner">
                            <div className="implementation">
                            <Container>

                                <Row>
                                    <Col xs='8' sm="6" md="6" lg="5" xl="5"  className="offset-2 offset-xss-0 col-xss-12 offset-sm-3 offset-md-3 offset-lg-1 offset-xl-1 offset-xxl-1 col-xxl-5  offset-smhd-1 col-smhd-5   offset-hd-1 col-hd-5  voice_img-inner">
                                        <div className="voice-over-svg-inner">
                                          <Lottie animationData={page.animation_image} loop={false}  />
                                        </div>

                                    </Col>
                                    <Col xs="10" sm="8" md="6" lg="6" xl="6"  className="offset-1 offset-xss-0 col-xss-12 offset-sm-2 offset-md-3 offset-lg-0 offset-xl-0 offset-xxl-0 col-xxl-6 offset-smhd-0 col-smhd-6 offset-hd-0 col-hd-5 ">
                                        <div className="implementation_texts" dangerouslySetInnerHTML={{__html: page.Implementation_text}}>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            </div>
                        </div>

                    </div>
            </Fragment>;
}

export default VoiceOver;