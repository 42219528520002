import React from "react";
import { useQuery } from "@apollo/react-hooks";
//import Lottie from "lottie-react";


const Query = ({ children, query, id }) => {
  const { data, loading, error } = useQuery(query, {
    variables: { id: id }
  });
  // console.log(data);
  if (loading) return (
    <div className="arrow-loading">
        <span>↓</span>
        <span >↓</span>
        <span >↓</span>
        <span >↓</span>
        <span >↓</span>
    </div>
          // <Lottie animationData={preloader} loop={true}  />
    );
  if (error) return <p>Error: {JSON.stringify(error)}</p>;
  
  return children({ data });
};

export default Query;
