import AboutMe from "../../components/AboutMe";
import Query from "../../components/Query";
import ABOUTME_PAGE_QUERY from "../../queries/about_me_page";

const AboutMeContainer = () => {
  return (
    <Query query={ABOUTME_PAGE_QUERY}>
        {({ data: { pageAboutMe } }) => {
        //console.log(pageAboutMe)
            return <AboutMe page={pageAboutMe} />;
        }}
    </Query>
  );
};

export default AboutMeContainer;
