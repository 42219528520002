import VoiceOver from "../../components/VoiceOver";
import Query from "../../components/Query";
import VOICEOVER_PAGE_QUERY from "../../queries/voice_over_page";

const VoiceOverContainer = () => {
  return (
    <Query query={VOICEOVER_PAGE_QUERY}>
        {({ data: { pageVoiceOver } }) => {
        //console.log(pageVoiceOver)
            return <VoiceOver page={pageVoiceOver} />;
        }}
    </Query>
  );
};

export default VoiceOverContainer;
