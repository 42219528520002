import React, { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useLocation } from 'react-router-dom';
function Footer() {
    const location = useLocation();

    const [foterStatus,setFoterStatus] = useState('');
    const [visiability,changeVisiability] = useState('hidden');

    useEffect(() => {
        if(location.pathname==='/' || location.pathname==='/admin'|| location.pathname==='/adminhome'){
            changeVisiability('hidden');
        }else{
            changeVisiability('show');
        }
        window.dispatchEvent(new Event('resize'));
    })
    
    return (
            <footer className={"footer "+ foterStatus }>
                <Container className={visiability }>
                    <Row>
                        <Col hd='6'>
                            <p className="footer__year">
                                Dimolosound 2023
                            </p>
                        </Col>
                        <Col hd='6' className="footer__work">
                            <Row>
                                <Col sm='12 '>
                                    <a href="https://www.behance.net/simpleuniverse" className="footer__designer" target="_blank">
                                        Designed by Simple
                                    </a>

                                </Col>
                                <Col sm='12'>
                                    <a href="#" className="footer__developer" target="_blank">
                                        Developed  by Vepbit
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </footer>
    )
}

export default Footer;
