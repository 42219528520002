import Implementation from "../../components/Implementation";
import Query from "../../components/Query";
import IMPLEMENTATION_PAGE_QUERY from "../../queries/implementation_page";
const ImplementationContainer = () => {
  return (
    <Query query={IMPLEMENTATION_PAGE_QUERY}>
        {({ data: { pageImplementation } }) => {
        //console.log(pageImplementation)
            return <Implementation page={pageImplementation} />;
        }}
    </Query>
  );
};

export default ImplementationContainer;
