import SoundDesign from "../../components/SoundDesign";
import Query from "../../components/Query";
import SOUNDDESIGN_PAGE_QUERY from "../../queries/sound_design_page";

const SoundDesignContainer = () => {
  return (
    <Query query={SOUNDDESIGN_PAGE_QUERY}>
        {({ data: { pageSoundDesign } }) => {
        // console.log(pageSoundDesign);
        // console.log(pageSoundDesign.MediaContent);
            return <SoundDesign page={pageSoundDesign} pagelength= {pageSoundDesign.MediaContent.length} />;
        }}
    </Query>
  );
};

export default SoundDesignContainer;
