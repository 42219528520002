import React from 'react';
import { Link } from "react-router-dom";
class NotFound extends React.Component {
    render(){
        return (
            <div class="error-page">        
                <h1>Page not Found</h1>
                <Link  to="about-me" class="go-home"> Main page</Link>
            </div>
        );
    }
}

export default NotFound;