import gql from "graphql-tag";

const IMPLEMENTATION_PAGE_QUERY = gql`
    query pageImplementation{
        pageImplementation {
            animation_image
            implementation_text
            Clients{
                image{
                formats
                }
            }
        }
    }
`;

export default IMPLEMENTATION_PAGE_QUERY;
