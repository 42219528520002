import Contact from "../../components/Contact";
import Query from "../../components/Query";
import CONTACT_PAGE_QUERY from "../../queries/contact_page";

const ContactContainer = () => {
  return (
    <Query query={CONTACT_PAGE_QUERY}>
        {({ data: { pageContact } }) => {
        //console.log(pageContact)
            return <Contact page={pageContact} />;
        }}
    </Query>
  );
};

export default ContactContainer;
