import gql from "graphql-tag";

const SOUNDDESIGN_PAGE_QUERY = gql`
{
    pageSoundDesign{
        MediaContent{
            title_row_one,
            title_row_two,
            url,
            contentType
        }
        }
}
`;

export default SOUNDDESIGN_PAGE_QUERY;
