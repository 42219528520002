import gql from "graphql-tag";

const ABOUTME_PAGE_QUERY = gql`
    query pageAboutMe{
        pageAboutMe{
            about_me_description,
            about_me_background{
                formats
            },
            Clients{
                link,
                image{
                    formats
                }
            }
        }
    }
`;

export default ABOUTME_PAGE_QUERY;
