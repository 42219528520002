import React, {Fragment} from "react";

import { useSwipeable } from "react-swipeable";

import {useHistory} from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// import VoiceImg from '../../images/voice.png';
// import Voice_1 from '../../images/company/impl/unreal.png';
// import Voice_2 from '../../images/company/impl/unity.png';
// import Voice_3 from '../../images/company/impl/fillod.png';
// import Voice_4 from '../../images/company/impl/wwise.png';

// import impementationAnimation from "../animations/impementation.json";

import Lottie from "lottie-react";


function Implementation ({page}) {
    // console.log(page);
    // console.log(link_server);

    // code for swipe START
    let history = useHistory();
    function redirectTo(params) {
        if(window.innerWidth < 768){
            history.push(params);
        }
        // console.log(params);
    }
    const handlers = useSwipeable({
      onSwipedLeft: () =>redirectTo('/voice-over'),
      onSwipedRight: () =>  redirectTo('/contact'),
      preventDefaultTouchmoveEvent: true,
      trackMouse: true,
      delta: 150
    });
    // code for swipe END
        return <Fragment>
                    <div className="wrapper wrapper_no-margin" {...handlers}>
                        <div className="voice-inner">
                            <div className="voice">
                            <Container>
                                <Row>
                                    {/* <Col lg="5" xl="4"  className="offset-smhd-1 col-smhd-3 col-hd-3  offset-hd-2  voice_img-inner">
                                        <img src={VoiceImg} alt="" className="voice_img" />
                                    </Col> */}

                                    <Col xs='8' sm="6" md="6" lg="5" xl="5"  className="offset-2 offset-xss-0 col-xss-12 offset-sm-3 offset-md-3 offset-lg-1 offset-xl-1 offset-xxl-1 col-xxl-5  offset-smhd-1 col-smhd-5   offset-hd-1 col-hd-5  voice_img-inner">
                                        <div className="implementation-svg-inner">
                                          <Lottie animationData={page.animation_image} loop={false}  />
                                        </div>

                                    </Col>
                                    <Col xs="10" sm="8" md="6" lg="6" xl="5"  className="offset-1 offset-xss-0 col-xss-12 offset-sm-2 offset-md-3 offset-lg-0 offset-xl-0 offset-xxl-0 col-xxl-6 offset-smhd-0 col-smhd-6 offset-hd-0 col-hd-5 ">
                                        <p className="voice_text"> {page.implementation_text}</p>
                                        <div className="sponcors">
                                            {
                                                page.Clients.map((item, index) => {
                                                    return(
                                                        <div className="sponcors__item" key={index}>
                                                            <img src={item.image.formats.thumbnail.url}  alt={item.image.formats.thumbnail.name} className="clients_img"/>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            </div>
                        </div>

                    </div>
            </Fragment>;
}

export default Implementation;

