import React from "react";
import {useHistory} from 'react-router-dom'

const Home = ({ page }) => {
    let history = useHistory();

    function redirectToAbout(params) {
        history.push("/about-me");
    }
        return  <div className="main-page" 
                    onWheel={()=>{
                        redirectToAbout();
                    }}
                    onTouchMove={()=>{
                        redirectToAbout();
                    }}>
            <h1 
                className="main-page_text" 
                onClick={()=>{
                    redirectToAbout();
                }}
                >
            <span className="main-logo__left">{page.title_left_part  ? page.title_left_part : 'DIMOLO'}</span><span className="main-logo__right">{page.title_right_part  ? page.title_right_part : 'DIMOLO'}</span> </h1>
        </div> ;
}

export default Home;