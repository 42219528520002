import gql from "graphql-tag";

const HOME_PAGE_QUERY = gql`
    query pageHome{
        pageHome {
            title_left_part,
            title_right_part
        }
    }
`;

export default HOME_PAGE_QUERY;
