import React,{useState, useEffect} from 'react';
import Menu from './Menu';

import { useLocation } from 'react-router-dom';

function Header (){
    const location = useLocation();
    const [visiability,changeVisiability] = useState('hidden');

    useEffect(() => {
        if(location.pathname==='/' || location.pathname==='/admin'|| location.pathname==='/adminhome'|| location.pathname===''){
            // console.log(location.pathname)
            changeVisiability('hidden');
        }else{
            // console.log('On other page');
            // console.log(location.pathname)
            
            changeVisiability('show');
        }
        window.dispatchEvent(new Event('resize'));
    })
    return(
        <header  className={'header '+ visiability}>
            <Menu />
        </header>
    )
}


export default Header;
